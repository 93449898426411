<button
	*transloco="let t"
	mat-icon-button
	[id]="id()"
	[class.sh-device-identifying]="isIdentifying()"
	[disabled]="disabled()"
	[matTooltip]="
		isIdentifying()
			? t('common.device-tooltips.identify.stop-identifying')
			: t('common.device-tooltips.identify.identify')
	"
	(click)="identifyDevice($event)"
>
	ID
</button>
